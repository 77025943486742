<template>
  <el-dialog
    :title="dialogType === 'edit' ? '编辑角色' : '新增角色'"
    :visible.sync="dialogFormVisible"
    :close-on-click-modal="false"
    @close="$emit('update:dialogVisible', false)"
    width="30%"
  >
    <div class="dialog-content">
      <el-form
        ref="form"
        :model="form"
        size="mini"
        :rules="rules"
        :label-position="'right'"
        label-width="auto"
      >
        <el-form-item label="角色名称" prop="roleName">
          <el-input v-model="form.roleName" clearable></el-input>
        </el-form-item>
        <el-form-item label="角色权限" prop="roleKey">
          <el-input
            v-model="form.roleKey"
            clearable
            :disabled="roleKeyDisabld"
          ></el-input>
        </el-form-item>
        <el-form-item label="角色说明" prop="remark">
          <el-input type="textarea" v-model="form.remark"></el-input>
        </el-form-item>
      </el-form>
    </div>
    <span slot="footer" class="dialog-footer">
      <el-button size="small" @click="$emit('update:dialogVisible', false)"
        >取 消</el-button
      >
      <el-button size="small" type="primary" @click="saveFn">确 定</el-button>
    </span>
  </el-dialog>
</template>
<script>
export default {
  name: 'addRole',
  props: {
    dialogVisible: {
      type: Boolean,
      require,
    },
    roleData: {
      type: Object,
      default: () => ({}),
    },
    dialogType: {
      type: String,
      require,
    },
  },
  data() {
    return {
      dialogFormVisible: this.dialogVisible,
      form: {
        roleName: '',
        roleKey: '',
        remark: '',
        roleId: '',
      },
      rules: {
        roleName: [{ required: true, message: '请输入角色名称' }],
        roleKey: [{ required: true, message: '请输入角色权限' }],
        remark: [{ required: true, message: '请输入角色说明' }],
      },
      roleKeyDisabld: false,
    };
  },

  mounted() {
    if (this.dialogType === 'edit') {
      for (let key in this.form) {
        this.form[key] = this.roleData[key] || '';
      }
    }
    if (
      this.form.roleKey == 'admin' ||
      this.form.roleKey == 'system_admin' ||
      this.form.roleKey == 'operations_user' ||
      this.form.roleKey == 'operations_admin' ||
      this.form.roleKey == 'general_user'
    ) {
      this.roleKeyDisabld = true;
    } else {
      this.roleKeyDisabld = false;
    }
  },
  methods: {
    add() {
      this.$api.sysManage.addRole(this.form).then((res) => {
        if (res.code == 0) {
          this.$message({
            message: res.msg,
            type: 'success',
          });
          this.$emit('update:dialogVisible', false);
          this.$emit('update');
        }
      });
    },
    eidt() {
      this.$api.sysManage.editRole(this.form).then((res) => {
        if (res.code == 0) {
          this.$message({
            message: res.msg,
            type: 'success',
          });
          this.$emit('update:dialogVisible', false);
          this.$emit('update');
        }
      });
    },
    saveFn() {
      this.$refs.form.validate((valid) => {
        if (valid) {
          this.dialogType === 'add' ? this.add() : this.eidt();
        }
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.dialog-content {
  width: 90%;
  margin: 0 auto;
}
</style>
