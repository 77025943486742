<template>
  <div class="main-container">
    <div class="pt-wrapper">
      <div class="hd-search">
        <table-search
          :model="searchParams"
          label-width="80px"
          :isClear="true"
          :flex="'4'"
          @search="searchFn('search')"
        >
          <search-item default label="">
            <el-input
              size="small"
              v-model="searchParams.roleName"
              placeholder="角色名称"
              clearable
            />
          </search-item>
          <search-item default label="">
            <el-select
              v-model="searchParams.status"
              placeholder="状态"
              clearable
            >
              <el-option
                v-for="item in roleStatus"
                :key="item.type"
                :label="item.name"
                :value="item.type"
              >
              </el-option>
            </el-select>
          </search-item>
          <search-item default label="">
            <el-date-picker
              size="small"
              v-model="searchParams.createTime"
              placeholder="创建时间"
              clearable
              value-format="yyyy-MM-dd"
            />
          </search-item>
        </table-search>
      </div>
      <div class="bd-content">
        <div class="table-opt">
          <el-button
            type="primary"
            class="el-icon-circle-plus-outline"
            @click="add"
          >
            新 增
          </el-button>
          <el-button type="warning" class="el-icon-delete" @click="del('more')">
            批量删除
          </el-button>
          <!-- <el-button type="success" class="el-icon-upload2" @click="exportRole">
            导出
          </el-button> -->
        </div>
        <pageTable
          ref="table"
          :columns="columnList"
          row-key="roleId"
          :params="searchParams"
          :api-function="this.$api.sysManage.queryRoleListData"
          @selectAll="selectAll"
          @select="select"
        >
          <template slot="status" slot-scope="scope">
            <el-switch
              v-model="scope.row.status"
              active-color="#13ce66"
              active-value="0"
              inactive-value="1"
              @change="changeState($event, scope.row)"
            >
            </el-switch>
          </template>
          <template slot="options" slot-scope="scope">
            <div class="data-operate">
              <span class="op-blue" @click="eidt(scope.row)"> 编辑 </span>
              <span class="op-blue" @click="toDetail(scope.row)">
                角色成员
              </span>
              <span class="op-green" @click="meuns(scope.row)"> 菜单分配 </span>
              <span class="op-red" @click="del(scope.row)"> 删除 </span>
            </div>
          </template>
        </pageTable>
      </div>
    </div>
    <add-role
      v-if="dialogVisible"
      :dialogType="dialogType"
      :dialogVisible.sync="dialogVisible"
      :roleData="roleData"
      @update="updateRowCb(dialogType)"
    />
    <menus-allocation
      v-if="menuDialogVisible"
      :dialogType="meunsType"
      :roleData="roleData"
      :dialogVisible.sync="menuDialogVisible"
      @update="updateRowCb(meunsType)"
    />
  </div>
</template>

<script>
import addRole from './component/rolecomponent/addRole.vue';
import menusAllocation from './component/rolecomponent/menusAllocation.vue';
export default {
  name: 'roleManage',
  components: {
    addRole,
    menusAllocation,
  },
  data() {
    return {
      selectData: [],
      meunsType: '',
      searchParams: {
        roleName: '',
        status: '',
        createTime: '',
      },
      roleStatus: [
        { name: '正常', type: '0' },
        { name: '停用', type: '1' },
      ],
      columnList: [
        {
          type: 'selection',
        },
        {
          label: '序号',
          type: 'index',
          width: 60,
        },
        {
          label: '角色名称',
          prop: 'roleName',
        },
        {
          label: '角色说明',
          prop: 'remark',
        },
        {
          label: '角色权限',
          prop: 'roleKey',
        },
        {
          label: '状态',
          prop: 'status',
          show: 'template',
        },
        {
          label: '创建时间',
          prop: 'createTime',
        },
        {
          label: '操作',
          prop: 'options',
          show: 'template',
          width: '200',
        },
      ],
      dialogVisible: false,
      dialogType: '',
      roleData: {},
      menuDialogVisible: false,
    };
  },
  methods: {
    changeState(callback, row) {
      var params = {
        roleName: row.roleName,
        roleKey: row.roleKey,
        remark: row.remark,
        roleId: row.roleId,
        status: callback,
      };
      this.$api.sysManage.editRole(params).then((res) => {
        if (res.code == 0) {
          this.$message({
            message: res.msg,
            type: 'success',
          });
          this.searchFn('edit');
        }
      });
    },
    selectAll(params) {
      this.selectData = params;
    },
    select(params) {
      this.selectData = params;
    },
    searchFn(status) {
      this.$refs.table.getPageDataOfAction(status);
    },
    add() {
      this.roleData = {};
      this.dialogType = 'add';
      this.dialogVisible = true;
    },
    eidt(row) {
      this.roleData = row;
      this.dialogType = 'edit';
      this.dialogVisible = true;
    },
    updateRowCb(type) {
      this.searchFn(type);
    },
    del(params) {
      if (params === 'more' && this.selectData.length === 0) {
        this.$message({
          message: '请先勾选数据',
          type: 'warning',
        });
        return;
      }
      const ids =
        params === 'more'
          ? this.selectData.map((i) => i.roleId).join()
          : params.roleId + '';
      this.$msg.confirm('确认删除角色？').then(() => {
        this.$api.sysManage
          .removeRole({
            ids: ids,
          })
          .then((res) => {
            if (res.code == 0) {
              this.$message({
                message: res.msg,
                type: 'success',
              });
              this.$refs.table.getPageDataBeforeDel(ids.split(','));
            }
          });
      });
    },
    meuns(row) {
      this.roleData = row;
      this.meunsType = 'edit';
      this.menuDialogVisible = true;
    },
    // 详情
    toDetail(row) {
      this.$router.push({
        path: '/sysManage/roleMember',
        query: {
          type: 'role',
          roleId: row.roleId,
          roleName: row.roleName,
        },
      });
    },
    exportRole() {},
  },
};
</script>
<style lang="scss" scoped></style>
