<template>
  <el-dialog
    :title="'菜单分配'"
    :visible.sync="dialogFormVisible"
    :close-on-click-modal="false"
    @close="$emit('update:dialogVisible', false)"
    width="30%"
  >
    <div class="dialog-content">
      <el-form
        ref="form"
        :model="form"
        size="mini"
        :rules="rules"
        :label-position="'right'"
        label-width="auto"
      >
        <el-form-item label="角色名称" prop="roleName">
          <el-input v-model="form.roleName" disabled></el-input>
        </el-form-item>
        <el-form-item label="菜单分配" prop="parentId">
          <el-select
            v-model="menuNames"
            multiple
            collapse-tags
            placeholder=""
            style="width: 100%"
          >
            <el-option :value="form.parentId" style="height: auto">
              <tree
                ref="tree"
                :tree-type="'form'"
                :params="{}"
                :showCheckbox="true"
                :checkedId="checkedId"
                @nodeChecked="treeNodeChecked"
                :api-function="this.$api.sysManage.menuTreeData"
              />
            </el-option>
          </el-select>
        </el-form-item>
      </el-form>
    </div>
    <span slot="footer" class="dialog-footer">
      <el-button size="small" @click="$emit('update:dialogVisible', false)"
        >取 消</el-button
      >
      <el-button size="small" type="primary" @click="saveFn">确 定</el-button>
    </span>
  </el-dialog>
</template>
<script>
import tree from '../tree.vue';

export default {
  name: 'addRole',
  props: {
    dialogVisible: {
      type: Boolean,
      require,
    },
    roleData: {
      type: Object,
      default: () => ({}),
    },
    dialogType: {
      type: String,
      default: '',
    },
  },
  components: {
    tree,
  },
  data() {
    return {
      dialogFormVisible: this.dialogVisible,
      form: {
        roleName: '',
        roleId: '',
        menuIds: [],
      },
      rules: {
        roleName: [{ required: true, message: '请输入角色名称' }],
        remark: [{ required: true, message: '请输入角色说明' }],
      },
      menuNames: [],
      checkedId: [],
    };
  },
  mounted() {
    if (this.dialogType) {
      var val = this.roleData;
      for (let key in this.form) {
        this.form[key] = val[key] || '';
      }
      this.checkedId = val.menuIds || [];
    }
  },
  // watch: {
  //   roleData: {
  //     deep: true,
  //     handler(val) {
  //       for (let key in this.form) {
  //         this.form[key] = val[key] || '';
  //       }
  //       this.checkedId = val.menuIds || [];
  //     },
  //   },
  // },
  methods: {
    add() {
      this.$api.sysManage.updateRoleMenu(this.form).then((res) => {
        if (res.code == 0) {
          this.$message.success(res.msg);
          this.$emit('update:dialogVisible', false);
          this.$emit('update');
        }
      });
    },
    saveFn() {
      this.$refs.form.validate((valid) => {
        if (valid) {
          this.add();
        }
      });
    },
    treeNodeChecked(checkarr) {
      this.form.menuIds = checkarr.map((i) => i.id);
      this.menuNames = checkarr.map((i) => i.label);
    },
  },
};
</script>
<style lang="scss" scoped>
.dialog-content {
  width: 90%;
  margin: 0 auto;
}
::v-deep .el-tree > .el-tree-node {
  display: block;
}
</style>
