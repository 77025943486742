<template>
  <div class="tree">
    <!-- <el-scrollbar wrap-class="tree-wrapper-scroller" style="height:100%"> -->
    <el-tree
      ref="Tree"
      :data="treeData"
      :expand-on-click-node="false"
      node-key="id"
      default-expand-all
      highlight-current
      :show-checkbox="showCheckbox"
      :filter-node-method="filterSqlNode"
      @node-click="handleNodeClick"
      @check-change="handleCheckChange"
      :render-content="renderContent"
    >
    </el-tree>
    <!-- </el-scrollbar> -->
  </div>
</template>

<script>
import { transformTree } from '@/utils/utils.js';

export default {
  props: {
    // 查询参数
    params: {
      type: Object,
      default: () => {
        return {};
      },
    },
    // renderContent类型
    treeType: {
      type: String,
      require,
    },
    // 外部传入数据
    tData: {
      type: Array,
      default: () => [],
    },
    // 请求数据的接口
    apiFunction: {
      type: Function,
      default: () => {},
    },
    // 配置数据
    defaultProps: {
      type: Object,
      default: () => {
        return {
          children: 'children',
          label: 'label',
        };
      },
    },
    // 展示checkbox
    showCheckbox: {
      type: Boolean,
      default: () => false,
    },
    // 勾选checkbox
    checkedId: {
      type: Array,
      default: () => [],
    },
    filterText: {
      type: String,
      default: '',
    },
    // 是否初始化的时候不加载
    initFalse: {
      type: Boolean,
      default: false,
    },
  },
  watch: {
    filterText(val) {
      this.$refs.Tree.filter(val);
    },
  },
  created() {
    if (this.initFalse) {
      this.treeData = this.tData.map((item) => item);
    } else {
      this.queryTreeData();
    }
  },
  data() {
    return {
      treeData: [],
    };
  },
  methods: {
    filterSqlNode(value, data) {
      if (!value) return true;
      return data.name.indexOf(value) !== -1;
    },
    handleNodeClick(data) {
      this.$emit('nodeClick', data);
    },
    handleCheckChange(data, checked, indeterminate) {
      const arr = this.$refs.Tree.getCheckedNodes(false, true);
      this.$emit('nodeChecked', arr);
    },
    queryTreeData() {
      this.apiFunction(this.params).then((res) => {
        this.treeData = transformTree(res.data, {
          pId: 'pid',
          label: 'name',
        });
        this.$emit('getCatalogueList', this.treeData);
        // 回显勾选数据
        if (this.checkedId.length > 0) {
          this.$nextTick(() => {
            this.$refs.Tree.setCheckedKeys(this.checkedId);
          });
        }
      });
    },
    renderContent(h, { node, data, store }) {
      let _html = null;
      switch (this.treeType) {
        case 'form': {
          _html = (
            <span class="custom-tree-node">
              <span class="tree-text">{node.label}</span>
            </span>
          );
          break;
        }
        // 行业管理左侧树顶级不可删除
        case 'com-industry': {
          _html =
            data.pid < 0 ? (
              <span class="custom-tree-node">
                <span class="tree-text">{node.label}</span>
              </span>
            ) : (
              <span class="custom-tree-node">
                <span class="tree-text">{node.label}</span>
                <span class="tree-fn">
                  <i
                    class="el-icon-edit i-blue"
                    on-click={() => this.edit(data)}
                  ></i>
                  <i
                    class="el-icon-delete i-red"
                    on-click={() => this.remove(data)}
                  ></i>
                </span>
              </span>
            );
          break;
        }
        case 'com': {
          _html = (
            <span class="custom-tree-node">
              <span class="tree-text">{node.label}</span>
              <span class="tree-fn">
                <i
                  class="el-icon-edit i-blue"
                  on-click={() => this.edit(data)}
                ></i>
              </span>
            </span>
          );
          break;
        }
        default: {
          break;
        }
      }
      return _html;
    },
    edit(params) {
      this.$emit('treeEdit', params);
    },
    remove(params) {
      this.$emit('treeRemove', params);
    },
  },
};
</script>

<style lang="scss">
.el-tree--highlight-current
  .el-tree-node.is-current
  > .el-tree-node__content
  .tree-text {
  color: #2d85ef;
}
.custom-tree-node {
  // flex: 1;
  padding-right: 50px;
  .tree-fn {
    // float: right;
    display: none;
    padding: 0 20px 0 5px;
    i {
      margin: 0 3px;
      cursor: pointer;
    }
    .i-blue {
      color: #2d85ef;
    }
    .i-red {
      color: red;
    }
  }
}
.custom-tree-node:hover {
  .tree-fn {
    display: inline-block;
  }
}
</style>
